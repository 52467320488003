/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    pre: "pre",
    code: "code",
    h3: "h3",
    h4: "h4",
    blockquote: "blockquote",
    p: "p",
    h5: "h5",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "CheatSheet"), "\n", React.createElement(_components.h2, null, "Check lint"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "TIMING=1 npm run lint\n")), "\n", React.createElement(_components.h2, null, "Support Absolute Paths"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=.eslint.json"
  }, "{\t\n  //...\t\n  \"settings\": {\t\n    \"import/resolver\": {\t\n      \"node\": {\t\n        \"paths\": [\"src\"]\t\n      }\t\n    } \t\n  },\t\n  //...\t\n}\t\n")), "\n", React.createElement(_components.h2, null, "Disable and Ignore Eslint"), "\n", React.createElement(_components.h3, null, "File Level"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "/* eslint-disable */\n")), "\n", React.createElement(_components.h4, null, "disable by rule on file"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "/* eslint no-use-before-define: 0 */  // --> OFF\n\nor\n\n/* eslint no-use-before-define: 2 */  // --> ON\n")), "\n", React.createElement(_components.h4, null, "Ignore next line"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "/* eslint-disable no-eval */\n\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "Put /* eslint-disable-line */ at the end of the line(s),\nor /* eslint-disable-next-line */ right before the line.\n")), "\n", React.createElement(_components.h2, null, "Setting Up ESLint and Prettier"), "\n", React.createElement(_components.h3, null, "Machine Level"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "🗒️ Installing at the machine level is a matter of prefference"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "brew install eslint\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "brew install prettier\n")), "\n", React.createElement(_components.h3, null, "Project Level"), "\n", React.createElement(_components.h4, null, "VSCode Prep"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json:title=\".vscode/setting.json\""
  }, "{\n  //....\n  \"editor.codeActionsOnSave\": {\n    \"source.fixAll.eslint\": true,\n    \"editor.defaultFormatter\": \"esbenp.prettier-vscode\"\n  },\n  //....\n}\n")), "\n", React.createElement(_components.p, null, "To have this Eslint and Prettier handle all languages"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json:title=\".vscode/setting.json\""
  }, "{\n //....\n    \"editor.formatOnSave\": true,\n    \"editor.defaultFormatter\": \"esbenp.prettier-vscode\",\n   //....\n}\n")), "\n", React.createElement(_components.p, null, "or configure by language"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json:title=\".vscode/setting.json\""
  }, "{\n //....\n \"[javascript]\": {\n    \"editor.formatOnSave\": true,\n    \"editor.defaultFormatter\": \"esbenp.prettier-vscode\",\n  },\n   //....\n}\n")), "\n", React.createElement(_components.h4, null, "Node/JavaScript Project Setup"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "npm install --save-dev eslint-config-prettier eslint-plugin-prettier prettier\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "touch .prettierrc\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "npx eslint --init\n")), "\n", React.createElement(_components.h5, null, "Eslint Config Example"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json:title=\".eslint.json\""
  }, "// .eslint.json\n{\n  \"env\": {\n    \"browser\": true,\n    \"es2021\": true\n  },\n  \"extends\": [\"prettier\", \"eslint:recommended\", \"plugin:react/recommended\"],\n  \"parserOptions\": {\n    \"ecmaFeatures\": {\n      \"jsx\": true\n    },\n    \"ecmaVersion\": 12,\n    \"sourceType\": \"module\"\n  },\n  \"plugins\": [\"prettier\", \"react\"],\n  \"rules\": {\n    \"prettier/prettier\": [\"error\"]\n  }\n}\n")), "\n", React.createElement(_components.h5, null, "Prettier Config Example"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json:title=\".prettierrc.json\""
  }, "// .prettierrc.json\n{\n  \"arrowParens\": \"avoid\",\n  \"semi\": false,\n  \"singleQuote\": true,\n  \"printWidth\": 70\n}\n")), "\n", React.createElement(_components.h5, null, "Turning Rules off"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json:title=\".eslint.json\""
  }, "{\n  // ...\n  \"rules\": {\n    \"prefer-const\": \"off\" // Turn rule off\n  }\n}\n")), "\n", React.createElement(_components.h4, null, "TypeScript Project Setup"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "npm i --dev @typescript-eslint/eslint-plugin\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json:title=\".eslint.json\""
  }, "{\n  \"plugins\": [\"@typescript-eslint\"],\n  \"extends\": [\n    \"next/core-web-vitals\",\n    \"plugin:@typescript-eslint/recommended\"\n  ],\n  \"rules\": {\n    // I suggest you add those two rules:\n    \"@typescript-eslint/no-unused-vars\": \"error\",\n    \"@typescript-eslint/no-explicit-any\": \"error\"\n  }\n}\n")), "\n", React.createElement(_components.h2, null, "References"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.robinwieruch.de/prettier-eslint"
  }, "Prettier & Eslint - Rw;eruch")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.robinwieruch.de/how-to-use-prettier-vscode"
  }, "Prettier Eslint in VSCode - Rw;eruch")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://paulintrognon.fr/blog/typescript-prettier-eslint-next-js"
  }, "Start a clean Next.js project with TypeScript, ESLint and Prettier - paulinTrognon.fr")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
